import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import ContactForm from '../components/ContactForm'
import Layout from '../components/Layout'
import SEO from '../components/seo'

const ContactUs = () => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO
        dir={intl.locale === 'en' ? 'ltr' : 'rtl'}
        title={intl.formatMessage({ id: 'contact_us' })}
        meta={intl.formatMessage({ id: 'meta_desc' })}
      />
      <ContactForm />
    </Layout>
  )
}

export default ContactUs
