import styled from 'styled-components'
import { colors, fonts } from '../../utils'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5rem 13rem;
  @media (max-width: 800px) {
    flex-direction: column;
    width: 80%;
    margin: 3rem auto;
  }
`

export const Section = styled.div`
  width: 50%;
  @media (max-width: 800px) {
    width: auto;
  }
`
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const Textarea = styled.textarea`
  font-family: ${fonts.secondary};
  padding: 12px;
  margin: 2rem 0;
  height: 200px;
  resize: vertical;
  border: 1px solid ${colors.gray2};
  border-radius: 5px;
  ::placeholder {
    font-family: ${fonts.secondary};
    color: ${colors.gray2};
  }
`
export const Input = styled.input`
  font-family: ${fonts.secondary};
  padding: 12px;
  margin-top: 2rem;
  border: 1px solid ${colors.gray2};
  border-radius: 5px;
  ::placeholder {
    font-family: ${fonts.secondary};
    color: ${colors.gray2};
  }
`
