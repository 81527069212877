import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { SecondaryButtonNoShadow } from '../Button/button.stories'
import { H3, ParagraphSmall } from '../typography'

import { Container, Section, Form, Input, Textarea } from './contactForm.style'

const contactForm = () => {
  const intl = useIntl()
  return (
    <Container>
      <Section>
        <H3 style={{ marginBottom: '1rem' }}>
          {intl.formatMessage({ id: 'contact_us' })}
        </H3>
        <ParagraphSmall>
          {intl.formatMessage({ id: 'contact_us_para' })}
        </ParagraphSmall>
      </Section>
      <Section>
        <Form>
          <Input
            name="fullname"
            type="text"
            placeholder={intl.formatMessage({ id: 'name' })}
          />
          <Input
            name="email"
            type="text"
            placeholder={intl.formatMessage({ id: 'email' })}
          />
          <Textarea
            name="message"
            placeholder={intl.formatMessage({ id: 'message' })}
          />
          <SecondaryButtonNoShadow
            text={intl.formatMessage({ id: 'send_message' })}
          />
        </Form>
      </Section>
    </Container>
  )
}

export default contactForm
