import React from 'react'

import Button from './index'
import ArrowIcon from '../../static/arrow.svg'
import AddIcon from '../../static/add.svg'

export default {
  title: 'components/button',
}

export const PrimaryButton = () => (
  <Button primary shadow text="Add to cart">
    <AddIcon />
  </Button>
)

export const SecondaryButton = () => (
  <Button shadow text="Check Out Now">
    <ArrowIcon />
  </Button>
)

// eslint-disable-next-line react/prop-types
export const SecondaryButtonNoShadow = ({ text }) => (
  <Button text={text}>
    <ArrowIcon />
  </Button>
)
